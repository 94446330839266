import { AppShell, Header, Image, Group, Container, UnstyledButton, Avatar, Text, createStyles, Box, Table, Title, Badge, Select, Button, Modal, SimpleGrid, Progress, ScrollArea, Paper, Skeleton, ActionIcon, Tooltip, useMantineColorScheme, Menu } from '@mantine/core';
import logoDark from '../assets/images/logo-dark.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Moment from 'react-moment';
import { getRecordInfo, getApiUrl, getStreamUrl  } from './Services';
import VideoFeed from './VideoFeed';
import { ArrowLeft, ArrowRight, ChevronDown, FaceId, ListDetails, Logout, Settings } from 'tabler-icons-react';
import AddFaceModal from './AddFaceModal';
import RecordsModal from './RecordsModal';
import LocationBadge from './LocationBadge';
import boopSfx from '../assets/sounds/beep.mp3';
import useSound from 'use-sound';

const useStyles = createStyles((theme, _params) => ({
  videoWrapper: {  
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    width: '100%',
    // height: '50vh',
    minHeight:400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.radius.sm,
    padding:theme.spacing.xl,
    marginTop:60
  },
  feedWrapper: {
    // subscribe to color scheme changes right in your styles
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    width: '100%',
    // height: 'auto',
    overflow:'hidden',
    flexGrow:1,
  },
  label:{
    fontSize:14,
    fontWeight:'bold',
    width:120
  },
  cameraWrapper:{
    // margin:15,
    position:'relative'
  }
  ,locationBadge:{
    position:'absolute',
    bottom:10,
    left:10
  },
  recogImage:{
    // borderRadius:theme.radius.md,
  },
  navButton:{
    '&:disabled':{
        opacity:.3
    }
  },
  recordTable:{
    '.selected':{
        backgroundColor:theme.colorScheme === 'dark'?theme.colors.dark[5]:theme.colors.gray[3],
    }
  }
}))

const Shell = ({cameraList, matchList, token}) => {

    const { classes } = useStyles();
    const [selectedRecord,setSelectedRecord] = useState(null);
    const [modalOpen, setModalOpened] = useState(false);
    const [addFaceOpened, setAddFaceOpened] = useState(false);
    const [showRecordsOpened, setShowRecordsOpened] = useState(false);
    
    const [selectedCamera,setSelectedCamera] = useState(null);
    const [records,setRecords] = useState([]);
    const [filteredRecords,setFilteredRecords] = useState([]);
    const [locations,setLocations] = useState([]);

    const { colorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';
    const feedWrapperRef = useRef();

    const [playSound, { stop }] = useSound(boopSfx);

    const alertTimer = useRef();


    /**
     * Fetches the record info and set the selected record
     * @param {*} item 
     */
     const fetchRecordInfo = useCallback(async(item) => {
        const data = await getRecordInfo(item.recordUuid,token);
        const record = {...data}
        setSelectedRecord(record)
    },[setSelectedRecord,token])

     /**
     * Method to play the alert
     * Continuously plays the alert every 1.2seconds
     */
      const playAlert = () => {

        console.log('playing alert')
        playSound();

        alertTimer.current = setInterval(() => {
            console.log('Alert Timer Playerr')
            playSound();
        },1200)
    
    };

    /**
     * Stop the alert and clear the timer
     */
    const stopAlert = () => {
        clearInterval(alertTimer.current);
        alertTimer.current = null;

        stop();
        console.log('stopping alert')
    }


    /**
     * Effect to handle new camera records and whether or not to display new records
     */
    useEffect(() => {
        var alertItems = [];

        // cheat comparison we may want to use somthing liken _underscore down the track
        var isMatch = JSON.stringify(matchList) === JSON.stringify(records);
        
        if(!isMatch){
            // console.log("NEW RECORDS ---------")
            // compare the match list agains the old list and popup and new records.
            matchList.forEach((val,idx) => {
                let item = records.find(record => val.recordUuid === record.recordUuid );

                if(!item){
                    alertItems.push(val);
                }
                    
            });

            setRecords([...matchList]);

            // if we have current matches select the record and show the popup
            if(alertItems.length){

                // play the sound
                playSound();
                if(!alertTimer.current)
                    playAlert();

                setSelectedRecord(alertItems[0]);
                setModalOpened(true);
                // get the record info
                fetchRecordInfo(alertItems[0]);

            }
        }
       

    },[matchList,fetchRecordInfo,records,playAlert])

    const closeRecognitionModal = () => {
        setModalOpened(false); 
        setSelectedRecord(null);
        stopAlert();
    }
    /**
     * Effect to filter out records for the selected camera
     */
    useEffect(() => {
        const rows = matchList.filter(val => val.camera.cameraUuid === selectedCamera.cameraUuid);
        setFilteredRecords(rows);

    },[selectedCamera, matchList])

    /**
     * Effect to set locations used for the selectbox data as it needs label and value
     */
    useEffect(() => {

        // select the first camera
        if(!selectedCamera && cameraList && cameraList.length){
            setSelectedCamera(cameraList[0]);
        }

        // add label and value for the select box to use
        const locations = cameraList.map(val =>({label:val.name, value:val.cameraUuid}));
        setLocations(locations);

    },[cameraList, selectedCamera])
    
    /**
     * Handles the selection of a camera record.
     * Opens the modal and fetches the data
     * @param {*} item 
     */
    const rowHandler = useCallback((item) => {
        setSelectedRecord({...item});
        setModalOpened(true);

        // get the record info
        fetchRecordInfo(item);
    },[setSelectedRecord,setModalOpened,fetchRecordInfo])

    

    /**
     * Handles the camera select box change
     * @param {*} cameraUuid 
     */
    const selectHandler = (cameraUuid) => {
        const camera = cameraList.find(val => val.cameraUuid === cameraUuid);
        if(camera){
            setSelectedCamera(camera);
        }
    }

    /**
     * Creates the rows for the table, we are using useMemo to prevent unessesary computations on each state update.
     */
    const rows = useMemo( () => {
        return filteredRecords.map((element,idx) => 
        {
            if(selectedCamera && element.camera.cameraUuid === selectedCamera.cameraUuid){
                return(<tr key={idx} onClick={() => rowHandler(element)} className={(selectedRecord && selectedRecord.recordUuid === element.recordUuid)?'selected':''} style={{cursor:'pointer'}}>
                <td align='left'><Text size='sm' sx={{fontWeight:'normal'}}>{element.person.name}</Text></td>
                <td  align='left'><LocationBadge camera={element.camera} /></td>
                <td align='left'><Moment>{element.frameTime}</Moment><br/><Text size='xs' color={'dimmed'}><Moment fromNow>{element.frameTime}</Moment></Text></td>
                <td align='left'>{element.targetGroups[0].name}</td>
                {/* <td align='left'>{element.viewed?<Badge size='sm' color={'teal'} variant='light'>Viewed</Badge>:<Badge size='sm' color={'green'}>New</Badge>}</td> */}
            </tr>)
            }else{
                return null;
            }
                
        }
    )},[filteredRecords, selectedRecord,rowHandler,selectedCamera]);
    
    // create the stream url based on camera uuid, values are set inside the streaming app .config
    // const streamURL = selectedCamera?`${process.env.REACT_APP_STREAM_HOST}/stream/${selectedCamera.cameraUuid}/channel/0/hls/live/index.m3u8`:'';
    const streamURL = selectedCamera?`${getStreamUrl(selectedCamera, false)}`:'';

    /**
     * Gets the current records index in the record list
     */
    const getSelectionIndex = useCallback(() => {        
        var idx = -1;
        if(selectedRecord){
            const item = filteredRecords.find((record) => selectedRecord.recordUuid === record.recordUuid);
            if(item){
                idx = filteredRecords.indexOf(item);
            }
        }       
        return {index:idx};

    },[selectedRecord,filteredRecords])
  
    const selectionIndex = getSelectionIndex().index;
    const selectedLocation = locations.find(val => val.value === selectedCamera.cameraUuid);

    const onMenuClick = (e) => {
        if(e === APP_CONSTANTS.ADD_FACE){
            //show add face modal
            setAddFaceOpened(true)
        }else if(e === APP_CONSTANTS.SHOW_RECORDS){
            setShowRecordsOpened(true);
        }
    }
    
    const feedWrapperHeight = feedWrapperRef.current?feedWrapperRef.current.offsetHeight - 120:250;

    return (
        <AppShell
            padding="md"
            header={<Header height={60} p="xs" sx={{background:'#231F20'}}>

            <Container>
                <Group position='apart'>
                    
                    <Image src={logoDark} height={30} width={100} fit="contain"></Image>

                    <Select                        
                        transition="pop-top-left"
                        transitionDuration={200}
                        transitionTimingFunction="ease"
                        placeholder="All Locations"
                        data={locations}
                        onChange={(e) => selectHandler(e)}
                        value={selectedLocation?.value}
                        />

                    <Group>                 

                        <Group position="center">
                            <Menu withArrow shadow="md" width={200}>
                                <Menu.Target>
                                    <UnstyledButton>
                                        <Group>
                                            <Avatar size={35} radius={'xl'} color="blue">OA</Avatar>
                                            {/* <div>
                                            <Text sx={{color:'white'}}>Ottica Admin</Text>
                                            </div> */}
                                            <ChevronDown color='white' size={16} />
                                        </Group>
                                    </UnstyledButton>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>Application</Menu.Label>
                                    <Menu.Item icon={<FaceId size={14} />} onClick={() => onMenuClick(APP_CONSTANTS.ADD_FACE)} >Add Face</Menu.Item>
                                    <Menu.Item icon={<ListDetails size={14} />} onClick={() => onMenuClick(APP_CONSTANTS.SHOW_RECORDS)} >Show Records</Menu.Item>
                                    <Menu.Item icon={<Settings size={14} />}>Settings</Menu.Item>
                                    <Menu.Item icon={<Logout size={14} />}>Logout</Menu.Item>
                                    
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    
                        
                        {/* <Tooltip label='Toggle theme'>
                        <ActionIcon
                            variant="outline"
                            color={dark ? 'yellow' : 'grape'}
                            onClick={() => toggleColorScheme('dark')}
                            title="Toggle color scheme"
                            >
                            {dark ? <Sun size={18} /> : <MoonStars size={18} />}
                        </ActionIcon>
                        </Tooltip> */}
                    </Group>
                    
                        
                </Group>
            </Container>

        </Header>}
        styles={(theme) => ({
            main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,  height:'calc(100vh - 60px)',marginTop:0, display:'flex', flexDirection:'column'},
           
        })}
        >
        {/* Your application here */}
        
        {/* <Box className={classes.videoWrapper}>
            {cameraList.map((camera,idx) => (
                <Paper className={classes.cameraWrapper}>
                    <ReactPlayer key={idx} url={camera.address} />
                    <Badge className={classes.locationBadge}>{camera.name}</Badge>
                </Paper>
            ))}
            
        </Box> */}
         <Box className={classes.videoWrapper}>
            <Container sx={{width:'100%', height:'100%'}}>
           
                <Paper sx={{width:'100%', height:'100%', background:'none'}} >
                    {selectedCamera?
                    <Group className={classes.cameraWrapper} sx={{minWidth:'100%', height:'100%'}}>
                    {/* <ReactPlayer url={streamURL} /> */}
                    <VideoFeed src={streamURL}/>
                    <LocationBadge camera={selectedCamera} className={classes.locationBadge}/>
                    
                    </Group>
                    :null}
                    
                </Paper>
                </Container>
            
        </Box>
        
        <Container className={classes.feedWrapper} ref={feedWrapperRef}>
            <Group py={'lg'} position='apart'>
                <Title order={3} sx={{textAlign:'left'}}>Recognition Records</Title>
                <Group>
                
                <Button size='sm'>Clear All</Button>
                </Group>
            </Group>

                    <ScrollArea style={{ height: feedWrapperHeight }}>
                    <Table highlightOnHover className={classes.recordTable}>
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Camera</th>
                        <th>Date</th>
                        <th>Target Group</th>
                        {/* <th>Status</th> */}
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                    </Table>
                    </ScrollArea>

            {!matchList || (matchList && matchList.length === 0) ?<Text mt={'xl'} color='dimmed' order={5}>There are currently no matches</Text>:null}
        </Container>
        
        <Modal
            overlayOpacity={dark?0.85:0.55}
            opened={modalOpen}
            onClose={() => {closeRecognitionModal()}}
            title="Recognition Record"
            size={'lg'}
            >   

            <Group sx={{position:'relative'}}>

                <Group sx={{position:'absolute', left:-100}}>
                    <Tooltip label='View the previous record'>
                    <ActionIcon className={classes.navButton} variant='light' size={'xl'} disabled={selectionIndex === 0} color={'grape'} onClick={() => rowHandler(filteredRecords[selectionIndex - 1])}>
                        <ArrowLeft size={32}></ArrowLeft>
                    </ActionIcon>
                    </Tooltip>
                </Group>


                <div style={{flexGrow:1}}>
                    {selectedRecord && selectedRecord.person?

                    <Group position='apart'>
                        <Box mb={'xl'}>
                            <FormControl label="Name" value={selectedRecord.person.name} />
                            {/* <FormControl label="Camera" value={selectedRecord.camera.name} /> */}
                            <Group sx={{width:'100%'}} mb={'sm'}>
                                <label className={classes.label}>Camera:</label>
                               <LocationBadge camera={selectedRecord.camera}/>                                
                            </Group>
                            
                            <FormControl label="Date" value={selectedRecord.frameTime} isDate={true} />
                            <Group sx={{width:'100%'}} mb='lg'>
                                <label className={classes.label}>Target Groups:</label>
                                {selectedRecord.targetGroups?
                                    (selectedRecord.targetGroups.map((val,idx) => <Badge key={idx} color={'gray'} variant='light'>{val.name}</Badge>))
                                :
                                <Text>N/A</Text>}
                                
                            </Group>

                            {/* <FormControl label="Target Group" value={selectedRecord.targetGroups[0].name} /> */}
                            <Group sx={{width:'100%'}}>
                                <label className={classes.label}>Similarity:</label>
                                {selectedRecord.similarity?
                                    <Progress sx={{width:200}} value={(selectedRecord.similarity*100)} label={`${(selectedRecord.similarity*100).toFixed(2)}%`} size="xl" color='teal' radius="xl" />
                                :
                                   <Text color={'dimmed'} size={'sm'}>Not Available</Text>
                                }
                                
                            </Group>
                        
                        </Box>

                    </Group>
                    
                    :null}
                    
                    <SimpleGrid cols={2}>
                        <div>
                            {selectedRecord?
                                <div style={{position:'relative'}}>
                                    <Image src={`${getApiUrl()}${selectedRecord.imageUrl}`} className={classes.recogImage} radius="md"/>
                                    {/* <Title mt={'md'} order={4}>Live</Title> */}
                                    <Badge className={classes.locationBadge} size='lg' variant='filled' color={'green'}>Live</Badge>
                                </div>
                            :null}
                            
                        </div>
                        <div>
                            {selectedRecord && selectedRecord.person.imageUrl?
                            <div style={{position:'relative'}}>
                                <Image src={`${getApiUrl()}${selectedRecord.person.imageUrl}`} className={classes.recogImage} radius="md" />
                                {/* <Title mt={'md'} order={4}>Database</Title> */}
                                <Badge className={classes.locationBadge} size='lg' variant='filled'>Database</Badge>
                            </div>
                            :
                            <Skeleton height={282} mt={0} width="100%" radius="md"></Skeleton>
                            }
                            
                        </div>
                    </SimpleGrid>

                    <Group mt={'xl'}>
                        <Button size='xl' fullWidth onClick={() => {closeRecognitionModal()}}>Acklowledge</Button>
                    </Group>
                </div>

               <Group sx={{position:'absolute', right:-100}}>
                    <Tooltip label='View the next record'>
                    <ActionIcon className={classes.navButton} variant='light' color={'grape'} size={'xl'} disabled={selectionIndex === (filteredRecords.length-1)} onClick={() => rowHandler(filteredRecords[selectionIndex + 1])}>
                        <ArrowRight size={32}></ArrowRight>
                    </ActionIcon>
                    </Tooltip>
                </Group>

            </Group>
        </Modal>


        <AddFaceModal token={token} locations={locations} onClose={(e) => setAddFaceOpened(false)} opened={addFaceOpened} />

        <RecordsModal token={token} locations={locations} onClose={(e) => setShowRecordsOpened(false)} opened={showRecordsOpened} />

        </AppShell>
    );
}

////////////////////////////////////////////////
//
// Control for Form display values
//
////////////////////////////////////////////////
const FormControl = ({label,value,isDate}) =>{
    const { classes } = useStyles();
    return (
        <Group mb={'sm'}>
            <label className={classes.label}>{label}:</label>
            <div>
                
                {isDate?
                <>
                    <Text size='sm'><Moment>{value}</Moment></Text>
                    <Text size='xs' color={'dimmed'}><Moment fromNow>{value}</Moment></Text>
                </>
                
                :<>
                <Text size='sm'>{value}</Text>
                </>
                }
                
            </div>
        </Group>
    )
}

export default Shell;

const APP_CONSTANTS = {
    ADD_FACE:'addface',
    SHOW_RECORDS:'showrecords'
}