import { Badge } from "@mantine/core";
import { Constants } from "../utils/constants";

const LocationBadge = ({camera,className}) => {
    
    const colour = camera && camera.cameraUuid === Constants.SMART_CAM_ID?'cyan':'indigo';
    const name = camera && camera.name?camera.name:'';
    return (
        <Badge color={colour} className={className}>{name}</Badge>
    )
}

export default LocationBadge;