import { Box, Button, Center, createStyles, Group, Image,Text } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useMemo, useRef, useState } from "react";
import { CloudUpload, Download, X } from "tabler-icons-react";
import { dataURItoBlob } from "../utils/utils";

const useStyles = createStyles((theme) => ({
    content: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    wrapper: {
      position: 'relative',
    //   marginBottom: 30,
    //   display:'flex',
    //   justifyContent:'center'
    },
  
    dropzone: {
      borderWidth: 1,
    //   paddingBottom: 50,
      width:300,
      padding:0,
      height:300,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  
    icon: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },
  
    control: {
      position: 'absolute',
      width: 250,
      left: 'calc(50% - 125px)',
      bottom: -20,
    },
  }));


export const FaceUpload = ({onConfirm,onPrevious,user}) => {
    const facemeRef = useRef(null);
    const [imgData, setImageData] = useState(null);
    const { classes, theme } = useStyles();
    const openRef = useRef(null);
    const [files, setFiles] = useState([]);
    
    const previews = useMemo(() => {

        return files.map((file, index) => {
            const imageUrl = URL.createObjectURL(file);
            return (
            <Image
                key={index}
                src={imageUrl}
                width={300}
                height={300}
                radius='md'
                fit="cover"
                className={classes.galleryImage}
                imageProps={{ onLoad: (e) =>{ 
                    URL.revokeObjectURL(imageUrl);
    
                    var reader = new FileReader();
                    reader.onload = function (e) {                
                        const data = dataURItoBlob(e.target.result);
                        setImageData(data);
                    };
                    reader.readAsDataURL(files[0]);            
                } }}
            />
            );
        });

    },[files,classes.galleryImage]) 


    return (

        <>      
        
            <div ref={facemeRef} style={{width:'100%', height:'100%'}} className={classes.content}>

            <div className={classes.wrapper}>

                
                <Dropzone
                    multiple={false}
                    openRef={openRef}
                    onDrop={setFiles}
                    className={classes.dropzone}
                    radius="md"
                    accept={[MIME_TYPES.jpeg,MIME_TYPES.png]}
                    maxSize={30 * 1024 ** 2}
                    >
                    <div style={{ pointerEvents: 'none' }}>

                        {files.length?
                            <Center>
                                {previews}
                            </Center>
                        :
                            <Box sx={{marginBottom:50}} p='lg'>
                                <Group position="center">
                                <Dropzone.Accept>
                                    <Download size={50} color={theme.colors[theme.primaryColor][6]}/>
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <X size={50} color={theme.colors.red[6]} />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <CloudUpload
                                    size={50}
                                    color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black}
                                    />
                                </Dropzone.Idle>
                                </Group>
                    
                                <Text align="center" weight={700} size="lg" mt="xl">
                                <Dropzone.Accept>Drop file here</Dropzone.Accept>
                                <Dropzone.Reject>Image file less than 30mb</Dropzone.Reject>
                                <Dropzone.Idle>Upload Face Image</Dropzone.Idle>
                                </Text>
                                <Text align="center" size="sm" mt="xs" color="dimmed">
                                Drag&apos;n&apos;drop files here to upload. We can accept only <i>image</i> files that
                                are less than 30mb in size.
                                </Text>
                            </Box>
                        }
                        
                        
                    </div>
                </Dropzone>
        
                <Button variant="filled" className={classes.control} size="md" radius="xl" onClick={() => openRef.current?.()} color="lime">Select files</Button>

            </div>

            
				{/* <div id="enroll_by_image_main" className="column" style={{display: 'block'}}>
					<div className="btn_container" style={{padding: '5px 0 0 0'}}>
						<div type="button" className="btn_select" id="btnSelectFaceImage" style={{position:'relative',cursor: 'pointer',margin:0, overflow: 'hidden'}} title="select image">
							<div id="fileinput" style={{padding:'0 20px 0 20px',width:'100%',display:'inhirit'}}>
								<div style={{display:'flex',flexDirection:'column',width:'100%',alignItems: 'center',justifyContent:'center'}}>
									<img src="./assets/images/icon_selectface.png" alt="" width="74" height="54"/>
									<span className="input_default_desc">Tap here to select face image for Enrollment</span>
								</div> 
							</div>
							<div id="imgpreview" 
								style={{
									width:'100%',
									height:'100%',
									display:'none',
									justifyContent: 'center',
									alignItems: 'center',
									overflow: 'hidden'}}>
								<img id="img_prev" src="#" alt="your image" 
									style={{flexShrink: 0,
									width:'100%',
									height:'100%',
									objectFit:'cover'}} />
							</div>             
							<div id="title_change_img" 
								style={{width:'100%',
										height:'40px',
										position: 'absolute',
										bottom: 0,
										backgroundColor: '#2d2d2d',
										opacity: 0.8,
										color:'#ffffff'}}>
								<span className="row aligncenter fullpanel" style={{fontSize: '16px',width:'100%',display:'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center',lineHeight: '20px'}}>
									Change Image
								</span>
							</div>        
						</div> 
						<input type='file' id="readFile" style={{display:'none'}} />
					</div>     
				</div> */}

            </div>


            <Group position="center" pt="xl" sx={{marginTop:30, paddingBottom:0}}>
                    <Button variant="default" onClick={()=>onPrevious()}>Back</Button>
                    <Button  onClick={()=> onConfirm({data: imgData, file:files[0],url:  URL.createObjectURL(files[0])})} disabled={!files.length}>Next Step</Button>
            </Group>
        </>

    )
}





  