import './App.css';
import Shell from './app/Shell';
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAllCameraDetail, getToken, listCameraRecords, listCameras } from './app/Services';
import { Constants } from './utils/constants';
import { useLocalStorage } from '@mantine/hooks';
import { NotificationsProvider } from '@mantine/notifications';


function App() {

  // use local storage to store the theme
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: 'mantine-color-scheme',
    defaultValue: 'dark',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value) => {
    const newScheme = colorScheme === 'dark'?'light':'dark';
    setColorScheme(newScheme);
  };

  const [token,setToken] = useState('');
  const [cameraList,setCameraList] = useState([]);
  const [matchRecords,setMatchRecords] = useState([]);
  const [error, setError] = useState(null);

  const pollRef = useRef();

  /**
   * Initialise the app grab the token then fetch the cameras
   */
  useEffect(() => {

    /**
     * Fetch all the cameras
     * @param {*} reqToken 
     */
    const fetchCameras = (reqToken) => {
      listCameras(reqToken).then((data) => {
        const uuids = data.results.map(val => val.cameraUuid)
        
        // get the camera details
        fetchAllCameraDetails(uuids,reqToken);
      })
      .catch((err) => {
        setError(err);
      })
    }

    getToken().then((data) => {
      setToken(data.token);

      // get the camera list
      fetchCameras(data.token);
    })
    .catch((err) => {
      setError(err);
    })
  },[]);

  /**
   * Fetch the camera details and set the camera list
   * @param {*} uuids 
   * @param {*} reqToken 
   */
  const fetchAllCameraDetails = (uuids,reqToken) => {
    getAllCameraDetail(uuids,reqToken).then((data) => {
      // console.log(data);
      setCameraList(data.results);      
    })
    .catch((err) => {
      setError(err);
    })
  }

  /**
   * Start Polling the camera for matches
   * If there is a match add it to the match list.
   * The match list combines all cameras.
   */
  const startPoll = useCallback(async() => {

    //let results = await fetchCameraRecords(cameraList[0].cameraUuid,token);
    pollRef.current = setInterval(async() => {
      
      var matchlist = [...matchRecords];
      for(var i=0; i<cameraList.length; i++){

        let data = await fetchCameraRecords(cameraList[i].cameraUuid,token);
       
        // check for matches
        let res = data.results.filter(item => item.person !== null);
        let matches = res?res:[];

        // only add ones that have changed
        matches.forEach((val,idx) => {
          let exists = matchlist.find((item) => item.recordUuid === val.recordUuid);
          if(!exists){
            matchlist.push(val);
          }
        });

        // console.log(res, " FETCHED CAM RECORDS")
    
        //matchlist = matchlist.concat(matches);        

        // if(results.results.length){
        //   let camera = {...cameraList[i]}
        //   let records = camera.records?[...camera.records]:[];
        //   records = records.concat(results.results);
        //   camera.records = records;
          
          
        //   tmpList[i] = camera;
        //   console.log(tmpList, " TMP LIST")
        //   setCameraList([...tmpList]);
        // }
        
      }

      // udpate the match list state
      setMatchRecords([...matchlist]);

    },Constants.POLL_INTERVAL);  

  },[cameraList,matchRecords,token])

  /**
   * Fetch the camera records
   * @param {*} uuid 
   * @param {*} reqToken 
   * @returns 
   */
  const fetchCameraRecords = async(uuid,reqToken) => {
    const startDate = new Date(Date.now() - Constants.CAM_DATE_SPAN_MS); // in millseconds
    return listCameraRecords(uuid,reqToken,startDate);
  }

  /**
   * If we have a list of cameras and the poll has not yet been started kick it off
   */
  useEffect(() => {
    if(cameraList && cameraList.length && !pollRef.current){
      startPoll();
    }      
  },[cameraList,startPoll])

  /**
   * Basic error handling we just output to the console
   */
  useEffect(()=>{
    if(error){
      console.error('Something went wrong')
    }else{
      setError(null)
    }
    
  },[error])

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{colorScheme,primaryColor:'grape'}} withGlobalStyles withNormalizeCSS>
      <NotificationsProvider>
        <div className="App">
          <Shell cameraList={cameraList} matchList={matchRecords} token={token} />
          {/* <FaceDetect token={token}/> */}
        </div>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
