import { MultipartParser } from "../utils/multipartParser";
export const getApiUrl = () =>{
    if(window._env_.REACT_APP_BASE_URL.startsWith("host"))
    {
        let port = window._env_.REACT_APP_BASE_URL.split(":")[1]
        return window.location.protocol + "//" + window.location.host + ":" + port;
    }
    return window._env_.REACT_APP_BASE_URL;
}

export const getStreamUrl = (selectedCamera, getMSEURL) =>{
    return "http://172.18.5.157:8080/memfs/8842237a-5edf-4b44-b634-e4da0498508c.m3u8";
    
    let host = window._env_.REACT_APP_STREAM_HOST;
    if(window._env_.REACT_APP_STREAM_HOST.startsWith("host"))
    {
        let port = window._env_.REACT_APP_STREAM_HOST.split(":")[1]
	    host = (getMSEURL?"ws://"+window.location.host:window.location.protocol + "//" + window.location.host) + ":" + port;
    }
    let path = getMSEURL?`/stream/${selectedCamera.cameraUuid}/channel/0/mse?uuid=${selectedCamera.cameraUuid}&channel=0`:
        `/stream/${selectedCamera.cameraUuid}/channel/0/hlsll/live/index.m3u8?_HLS_msn=49&_HLS_part=6`;
    return host + path;
    
}
export const getToken = () => {
 
    const url = `${getApiUrl()}/api/v1/api_key/auth`;
  
    const body = {
        apiKey:window._env_.REACT_APP_API_KEY,
    }
  
    const formBody = encodeBodyData(body);
  
    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Accept':'*/*'           
        },
    method: 'POST',
    body: formBody,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        console.log(data)
        return data;
    })
    .catch((err) => {
       console.error('ERROR getting auth key',err);
        throw(err);
    })
  }
  
  export const listCameras = (token) => {
 
    const url = `${getApiUrl()}/api/v1/camera/list`;
    
    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization':`${token}`, 
        },
    method: 'POST',
    // body: formBody,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        console.log(data)
        return data;
    })
    .catch((err) => {
       console.error('ERROR getting auth key',err);
        throw(err);
    })
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate()
        //year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    //return [year, month, day].join('-');
    //var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),date.getUTCDate(), date.getUTCHours(),date.getUTCMinutes(), date.getUTCSeconds());

    return date.toISOString();

  }
 

  export const listCameraRecords = (uuid,token,startDate,pageSize=2000) => {
 
    var url = `${getApiUrl()}/api/v1/camera/record/list`;
    //var startDate = new Date(Date.now() - Constants.CAM_DATE_SPAN_MS); // in millseconds
    var endDate = new Date(Date.now());

    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    
    url += `?cameraUuid=${encodeURIComponent(uuid)}`;
    url += `&startDate=${encodeURIComponent(startDate)}`;
    url += `&endDate=${encodeURIComponent(endDate)}`;
    url += `&pageSize=${pageSize}`;

    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization':`${token}`, 
        },
    method: 'POST',
    // body: formBody,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        if(data && data.results){
            data.results = data.results.map(item => {
                return ({...item,viewed:false})
            });

            var tmp = data.results.filter(val => val.person);
            console.log(tmp, ' persons')
        }
        
        return data;
    })
    .catch((err) => {
       console.error('ERROR getting camera records',err);
        throw(err);
    })
  }
      
  export const getAllCameraDetail = (uuids,token) => {
    const url = `${getApiUrl()}/api/v1/camera/list/detail`;
    // const body = {
    //     cameraUuid:uuids,
    // }
    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization':`${token}`, 
        },
    method: 'POST',
    // body: formBody,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        console.log(data)
        return data;
    })
    .catch((err) => {
       console.error('ERROR getting camera detail',err);
        throw(err);
    })
  }
  
  export const getRecordInfo = (uuid,token) => {
 
    var url = `${getApiUrl()}/api/v1/camera/record/info`;

    const body = {
        recordUuid:uuid,
    }
  
    const formBody = encodeBodyData(body);
  
    console.log(token, url)
    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization':`${token}`, 
        },
    method: 'POST',
    body: formBody,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        console.log(data)
        return data;
    })
    .catch((err) => {
       console.error('ERROR getting record info',err);
        throw(err);
    })
  }

  export const queryGrouplist = (token) => {
    var url = `${getApiUrl()}/api/v1/group/list/detail`;
    
    return fetch(url, {
        headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization':`${token}`, 
        },
        method: 'POST',
        
        }).then((response) => {
            return response.json();     
        }).then((data)=>{
            console.log(data)
            return data;
        })
        .catch((err) => {
        console.error('ERROR getting record info',err);
            throw(err);
        });
}

// async function enrollPerson(data) {
//   try {
//     const result = await axios({
//       method: "post",
//       url: APIS.ADD,
//       data: data,
//       headers: {
//         Authorization: getToken(),
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return result.data;
//   } catch (e) {
//     console.log("[API error] enroll person", e.response.data);
//     if (e.response.status === 401) onUseInvalidToken();
//     return e.response.data;
//   }
// }
export const enrollPerson = (data,token) => {
    var url = `${getApiUrl()}/api/v1/person/add`;
    
    return fetch(url, {
        headers: {
        //  'Content-Type':  "multipart/form-data",
         'Authorization':`${token}`, 
        },
        method: 'POST',
        body: data,
        }).then((response) => {
            return response.json();     
        }).then((data)=>{
            console.log(data)
            if(data.statusCode && data.statusCode === 404){
                throw(data.errorMessage);
            }
            return data;
        })
        .catch((err) => {
            console.error('ERROR getting record info',err);
            throw(err);
        });
    
}
export function checkAntispoofingSetting(){
    var antiSpoofingType = localStorage.getItem("antiSpoofingType");
    let precisionLevel = localStorage.getItem("precisionLevel");
    let raNum = localStorage.getItem("raNum");
    let raNodEnable = localStorage.getItem("raNodEnable");
    let raSmileEnable = localStorage.getItem("raSmileEnable");    
  
    if (antiSpoofingType === null) {
        antiSpoofingType = "TWO_STAGES_WITH_RANDOM_ACTIONS";
        localStorage.setItem("antiSpoofingType", antiSpoofingType);
    }
    if (precisionLevel === null) {
        precisionLevel = "standard";
        localStorage.setItem("precisionLevel", "standard");
    }
    if (raNum === null) {
        raNum = 2;
        localStorage.setItem("raNum", 1);
    }
    if (raNodEnable === null) {
        raNodEnable = "true";
        localStorage.setItem("raNodEnable", "false");
    }
    if (raSmileEnable === null) {
        raSmileEnable = "false";
        localStorage.setItem("raSmileEnable", "false");
    }      
  }


/**
 * Pass image data from FaceMe SDK to server to identify if it belongs to a real person or not.
 *
 * Please note for this version we hard-coded the auth header to "cyberlink:377339aa107ae58abe8e3c7fd30218b6" and we will
 * change it in the future.
 *
 * @param {JSON} detail - the information server needed; you can pass it directly from FaceMe SDK
 * @param {blob[]} spoofingcheckImages - the image data server needed; you can pass it directly from FaceMe SDK
 * @param {JSON} features - Apply features on antiSpoofing
 * @return {object[]} a array of response which can be json or images; it contains the information whether our user is a
 * real person or not. It also return one image with best quality for further usage.
 */
export async function antiSpoofing(detail, spoofingcheckImages, features, token) {
    let formData = new FormData();
    formData.append('detail', JSON.stringify(detail));
    for (const [key, images] of Object.entries(spoofingcheckImages)) {
        images.forEach((img) => {
            formData.append(key, img);
        });
    }
    if (features) {
        formData.append('features', JSON.stringify(features));
    }
    try {
        const api = localStorage.getItem("antiSpoofingType") === "TWO_STAGES_WITH_RANDOM_ACTIONS" ? "/api/v1/anti_spoofing/spoofingcheck_two_stage" : "/api/v1/anti_spoofing/spoofingcheck_one_stage";
        
        const response = await fetch(`${getApiUrl()}${api}`, {
            headers: {
             'Content-Type': 'application/x-www-form-urlencoded',
             'Authorization':`${token}`, 
            },
            responseType: 'arraybuffer',
            method: 'POST',
            
            })

        // const response = await fetch(api, formData, {
            
        //     responseType: 'arraybuffer', headers: {
        //         'Authorization': getToken()
        //     }
        // });
        return Promise.resolve(handleMultiPartResponse(response));
    } catch (e) {
        if (e.response.status === 401) onUseInvalidToken();
        return Promise.resolve(handleMultiPartResponse(e.response));
    }
}


/**
 * Parse the response to json or binary image
 *
 * @param {ArrayBuffer} response - ArrayBuffer response from server
 * @return {object[]} array of response which can be json or images
 */
 const handleMultiPartResponse = (response) => {
    if (!response) {
        return [{json: {statusCode: 500, errorMessage: "Server timout"}}];
    }
    const contentType = response.headers["content-type"];
    if (contentType === "application/json") {
        const parseData = String.fromCharCode.apply(null, new Uint8Array(response.data));
        return [{json: JSON.parse(parseData)}];
    }
    //const boundary = getBoundary(response.headers["content-type"]);
    const boundary = '';
    const byteArray = new Uint8Array(response.data);
    return MultipartParser.parse(byteArray, boundary);
};



  const encodeBodyData = (body) => {
    return Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&'); 
  }

  
function onUseInvalidToken()
{
    // clearToken()
    // clearAPIKey();
    // localStorage.setItem("reload_apikey", true)
    // if (document.referrer === "") {
    //     window.close()
    // } else {
    //     history.back()
    // }
}
