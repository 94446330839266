import { Avatar, Box, Button, createStyles, Group, Modal, NativeSelect, Progress, Stepper, Text, Textarea, TextInput, Title, UnstyledButton, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useInterval } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState} from "react";
import { At, Check, FaceId, Note, Social, Upload } from "tabler-icons-react";
import { FaceDetect } from "./FaceDetect";
import { FaceUpload } from "./FaceUpload";
import { enrollPerson, queryGrouplist } from "./Services";


const useStyles = createStyles((theme, _params) => ({
    actionButton: {  
      height:80
    },
    image:{
        width:'100%',
        height: 'auto',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 200,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        transition: 'box-shadow 150ms ease, transform 100ms ease',
        border:`1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[3],
        padding:theme.spacing.xl,
        '&:hover': {
          boxShadow: `${theme.shadows.md} !important`,
          transform: 'scale(1.05)',
        },
    },
    icon: {
       color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
    },
    
    name: {     
      
    },
    button: {
        position: 'relative',
        transition: 'background-color 150ms ease',
      },
    
      progress: {
        position: 'absolute',
        bottom: -1,
        right: -1,
        left: -1,
        top: -1,
        height: 'auto',
        backgroundColor: 'transparent',
        zIndex: 0,
      },
    
      label: {
        position: 'relative',
        zIndex: 1,
      },
}))

const AddFaceModal  = ({onClose, token, locations,opened}) => {

    const [groups, setGroups] = useState([]);
    const [formValid,setFormValid] = useState(false);
    const { classes } = useStyles();
    const [enrollType,setEnrollType] = useState('image');
    const theme = useMantineTheme();
    const [imgCapture,setImgCapture] = useState('');
    // const [faceData, setFaceData] = useState();
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 4 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const selectData = groups.map(val => ({value:val.groupUuid, label:val.name}));

    const reset = () => {
        form.setValues({
            name: '',
            email: '',
            group:'',
            note:'',
            img:'',
        });
        setActive(0);
    }

    const form = useForm({
        initialValues: {
          name: '',
          email: '',
          note: '',
          group:'',
          img:'',
        },
    
        validate: {
          name: (value) => (value.length?null:'You must enter a name'),
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
      });

    useEffect(() => {
        async function fetchData(token) { 
            let tmpGroups = await queryGrouplist(token);
            setGroups(tmpGroups.results)
        }
        if(!groups.length && token)
            fetchData(token);
    },[token,groups.length])

    const getGroupById = (id) => {
        const group = groups.find(val => val.groupUuid === id);
        return group?group:{name:'All',groupUuid:''}
    }

    const onFaceConfirm = (e) => {
        form.setFieldValue({img:e.data});
        setImgCapture(e);
        nextStep();
    }

    const addPerson = async() => {
        let formData = new FormData();
        formData.append("name", form.getInputProps('name').value);
        formData.append("email", form.getInputProps('email').value);
        formData.append("note", form.getInputProps('note').value);
        formData.append("groupUuid", form.getInputProps('group').value);
        formData.append("image", imgCapture.data);
        formData.append("skipQC", true);
        
        try {
            const data = await enrollPerson(formData,token);
            if(data)
                nextStep();
        } catch (e) {
            console.error(e);
            showNotification({
                title: 'Unable to save',
                message: 'Unfortunately there was an issue creating your record. Please try again later',
                color:'red'
            });
            //prevStep();
            
        } 
        
    }
    
    const onFormChange = (e) => {
      console.log(e)
      const result = form.validate();
      const isValid = !result.hasErrors;

      if(formValid !== isValid){
        console.log(isValid, formValid)
        setFormValid(!result.hasErrors);
      }
      
      form.clearErrors();
    }

    // useEffect(() => {

    //     // validate the form for next click
    //     const result = form.validate();
    //     const isValid = !result.hasErrors;

    //     if(formValid !== isValid){
    //       console.log(isValid, formValid)
    //       setFormValid(!result.hasErrors);
    //     }
        
    //     form.clearErrors();
        
    // },[form.values,form]);

    return (
        <Modal
            opened={opened}
            onClose={() =>{ onClose(false); reset()}}
            title="Add Face"
            size={'xl'}
            closeOnClickOutside={false}
            >

            <form onSubmit={form.onSubmit((values) => console.log(values))} onChange={(e) => onFormChange(e)}>

            <Stepper active={active} onStepClick={setActive} breakpoint="sm">
                
                {/* Details Step */}
                <Stepper.Step label="First step" description="Enter user details">
                    <div>
                        <TextInput
                            required
                            label="Name"
                            placeholder="Enter Name"
                            {...form.getInputProps('name')}
                            mb='lg'
                            />

                        <TextInput
                            required
                            label="Email"
                            placeholder="your@email.com"
                            {...form.getInputProps('email')}
                            mb='lg'
                            />

                        <Textarea
                            label="Notes"
                            placeholder="Enter some notes about the person"
                            {...form.getInputProps('note')}
                            mb='lg'
                            />

                        {locations?
                            <NativeSelect
                            data={selectData}
                            placeholder="Pick one"
                            label="Select your Group"
                            {...form.getInputProps('group')}
                            mb='lg'
                            />
                        :null}

                        <Group position="center" mt="xl">
                            {/* <Button variant="default" onClick={prevStep}>Back</Button> */}
                            <Button onClick={nextStep} disabled={!formValid}>Next step</Button>
                        </Group>

                    </div>

                </Stepper.Step>

                {/* Capture Type Step */}
                <Stepper.Step label="Second step" description="Capture Type">

                    <Group position="center" my={'xl'} sx={{flexWrap:'nowrap'}}>

                        <UnstyledButton className={classes.item} onClick={() => {nextStep(); setEnrollType(MODES.CAPTURE_MODE)}}>
                            <FaceId color={theme.colorScheme === 'dark' ? 'cyan' : theme.colors.cyan[7]} size={42} />
                            <Group mt={7}>
                            <div>
                                <Title order={4}>Enroll with Camera</Title>
                                <Text size={'sm'} color='dimmed'>Select a face image from your device to enroll</Text>
                            </div>
                            </Group>
                        </UnstyledButton>

                        <UnstyledButton className={classes.item} onClick={() => {nextStep(); setEnrollType(MODES.CAMERA_MODE)}}>
                            <Upload color={theme.colorScheme === 'dark' ? 'cyan' : theme.colors.cyan[7]} size={42} />
                            <Group mt={7}>
                                <div>
                                    <Title order={4}>Enroll with Face Image</Title>
                                    <Text size={'sm'} color='dimmed'>Upload a face image from your device to enroll</Text>
                                </div>
                            </Group>
                        </UnstyledButton>
                    </Group>

                    <Group position="center" mt="xl">
                        <Button variant="default" onClick={prevStep}>Back</Button>
                    </Group>

                </Stepper.Step>
                
                {/* Face capture */}
                <Stepper.Step label="Third step" description="Capture Face">
                    <Box sx={{width:'100%', height:420, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        {/* if using the camera */}
                        {enrollType === MODES.CAPTURE_MODE?
                        <>
                            <FaceDetect onConfirm={(e) => onFaceConfirm(e)} onPrevious={prevStep} />
                            <Group position="center" mt="xl">
                                <Button variant="default" onClick={prevStep}>Back</Button>
                            </Group>
                        </>
                        :
                        <FaceUpload onConfirm={(e) => onFaceConfirm(e)} onPrevious={prevStep}/>
                        }                        
                        
                    </Box>
                    
                </Stepper.Step>

                {/* Confirm */}
                <Stepper.Step label="Final step" description="Confirm details">
                        <Group position="center" sx={{height:400}}>
                            <UserInfoIcons name={form.getInputProps('name').value} email={form.getInputProps('email').value} note={form.getInputProps('note').value} group={getGroupById(form.getInputProps('group').value)} avatar={imgCapture.url} />
                        </Group>
                        <Group position="center" mt="xl">
                            <Button variant="default" onClick={prevStep}>Back</Button>
                            {/* <Button onClick={() => {addPerson()}}>Confirm</Button> */}
                            <ButtonProgress onClick={() => {addPerson()}} />
                        </Group>
                </Stepper.Step>

                {/* Complete */}
                <Stepper.Completed>
                    <Group sx={{height:360}} position="center">
                        <Box sx={{textAlign:'center'}}>
                            <Check size={46} color='teal' />
                            <Title order={3}>Record added</Title>
                            <Text>Would you like to enter another record?</Text>
                            <Group position="center" mt="xl">
                                <Button variant="default" onClick={() => {onClose(false); reset()}}>Close</Button>
                                <Button onClick={() => reset()}>Add another record</Button>
                            </Group>
                        </Box>
                    </Group>
                </Stepper.Completed>
            </Stepper>
              
            </form>

            {/* <Button ref={detectDone}>Detect</Button> */}

        </Modal>
    )
}

export default AddFaceModal;


export function ButtonProgress({onClick}) {
  const { classes, theme } = useStyles();
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const interval = useInterval(
    () =>
      setProgress((current) => {
        if (current < 90) {
            return current + 1;
        }else{
            return 90;
        }
      }),
    20
  );
  return (
    <Button
      className={classes.button}
      onClick={() => {(loaded ? setLoaded(false) : !interval.active && interval.start()); onClick()}}
      color={loaded ? 'teal' : theme.primaryColor}
    >
      <div className={classes.label}>
        {progress !== 0 ? 'Uploading record' : loaded ? 'Data uploaded' : 'Confirm'}
      </div>
      {progress !== 0 && (
        <Progress
          value={progress}
          className={classes.progress}
          color={theme.fn.rgba(theme.colors[theme.primaryColor][2], 0.35)}
          radius="sm"
        />
      )}
    </Button>
  );
}


export function UserInfoIcons({ avatar, name, group, email, note }) {
    const { classes } = useStyles();
    
    return (
      <div>
        
        <Group noWrap>
          <Avatar src={avatar} size={200} radius="xl" />
          
          <div>
            {/* <Text size="xs" sx={{ textTransform: 'uppercase' }} weight={700} color="dimmed">
              {title}
            </Text> */}
  
            <Text size="lg" weight={500} className={classes.name}>
              {name}
            </Text>
  
            <Group noWrap spacing={10} mt={3}>
              <At size={16} className={classes.icon} />
              <Text size="xs" color="dimmed">
                {email}
              </Text>
            </Group>

            <Group noWrap spacing={10} mt={3}>
              <Note size={16} className={classes.icon} />
              <Text size="xs" color="dimmed">
                {note}
              </Text>
            </Group>
  
            <Group noWrap spacing={10} mt={5}>
              <Social size={16} className={classes.icon} />
              <Text size="xs" color="dimmed">
                {group.name}
              </Text>
            </Group>
          </div>
        </Group>
      </div>
    );
  }

  const MODES = {
    CAMERA_MODE:'camera',
    CAPTURE_MODE:'capture'
  }