import { CloseButton, Container, createStyles, Group, Image, Input, Modal, ScrollArea, Skeleton, Table, Text, Title } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import Moment from "react-moment";
import { Search } from "tabler-icons-react";
import LocationBadge from "./LocationBadge";
import { listCameraRecords, getApiUrl } from "./Services";

const useStyles = createStyles((theme, _params) => ({
    recordTable:{},
}))

const RecordsModal  = ({onClose, token, locations, opened}) => {

  const { classes } = useStyles();
  const [records, setRecords] = useState([]);
  const [keyword,setKeyword] = useState('');
  const [filtredRecords, setFilteredRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const scrollHeight = window.innerHeight -200;

  /**
   * Fetch the records when we open the modal
   */
  useEffect(() => {

      const fetchCameraRecords = async(locations,reqToken) => {
        setIsLoading(true);
        var items = [];
        try{
          for(let i = 0; i<locations.length; i++){
            const startDate = new Date(2000, 0, 0)
            const data = await listCameraRecords(locations[i].value,reqToken,startDate,1000);
            items = items.concat(data.results);
          }
          setRecords(items);
          setFilteredRecords(items);
          setIsLoading(false);
        }catch(e){
          console.error('Unable to fetch records');
        }
      }

      if(opened){
        fetchCameraRecords(locations,token);
      }
      
  },[opened,locations,token])

   /**
   * Creates the rows for the table, we are using useMemo to prevent unessesary computations on each state update.
   */
   const rows = useMemo( () => {

    // show skeleton loader
    if(isLoading){
      return [1,2,3,4,5].map((elem,idx) => {
        return (<tr key={idx}>
          <td><Group><Skeleton height={60} width={60} radius="md" /> <Skeleton height={8} width={100} radius="xl" /></Group></td>
          <td><Skeleton height={8} mt={6} width={170} radius="xl" /></td>
          <td><Skeleton height={8} mt={6} width={'auto'} radius="xl" /></td>
          <td><Skeleton height={8} mt={6} width={'100%'} radius="xl" /></td>
        </tr>)
      })
    }

    // return rows
    return filtredRecords.map((element,idx) => 
    {
      const personName = element.person?<Text size='sm' sx={{fontWeight:'normal'}}>{element.person?.name}</Text>:<Text size='sm' color={'dimmed'} sx={{fontWeight:'normal'}}>Not Available</Text>;
      
      return(<tr key={idx} style={{cursor:'pointer'}}>
          <td align='left'>
            <Group>
              <Image withPlaceholder radius={'md'} src={`${getApiUrl()}${element.imageUrl}`} width={60} />
              {personName}
            </Group>
          </td>
          <td  align='left'><LocationBadge camera={element.camera} /></td>
          <td align='left'><Moment>{element.frameTime}</Moment><br/><Text size='xs' color={'dimmed'}><Moment fromNow>{element.frameTime}</Moment></Text></td>
          <td align='left'>{element.targetGroups[0].name}</td>
      </tr>)
       
    }
  )},[filtredRecords,isLoading]);
  
  /**
   * Handler for keyword change, filter the records
   */
  useEffect(() => {
    if(keyword.length){
      const items = records.filter((val => {
        var result = false;
        // match persons name
        if(val.person && val.person.name.toLowerCase().indexOf(keyword) > -1){
          result = true;
        }

        if(val.camera && val.camera.name.toLowerCase().indexOf(keyword) > -1){
          result = true;
        }

        if(val.targetGroups && val.targetGroups[0].name.toLowerCase().indexOf(keyword) > -1){
          result = true;
        }

        return result; 
      }));
      setFilteredRecords([...items])
    }else{
      setFilteredRecords([...records]);
    }
          
  },[keyword,records])
  
  return (
    <Modal
        opened={opened}
        onClose={() =>{ onClose(false);}}
        title=""
        fullScreen
        closeOnClickOutside={false}
        >

        <Container className={classes.feedWrapper}>
          <Group py={'lg'} position='apart'>
              <Group>
                <Title order={3} sx={{textAlign:'left'}}>Recognition Records</Title>
                <Text color={'dimmed'} size='sm'>Displaying {filtredRecords.length} of {records.length} records</Text>
              </Group>
              <Group>
                <Input
                  icon={<Search />}
                  placeholder="Search Records"
                  value={keyword}
                  onChange={(e) => setKeyword(e.currentTarget.value)}
                  rightSection={<CloseButton size={14} stroke={1.5} onClick={(e)=>{setKeyword('')}} />}
                />
              {/* <Button size='sm'>Clear All</Button> */}
              </Group>
          </Group>

          <ScrollArea sx={{height:scrollHeight}} >
          <Table highlightOnHover className={classes.recordTable}>
          <thead>
              <tr>
                <th style={{width:200}}>Person</th>
                <th style={{width:100}}>Camera</th>
                <th style={{width:'auto'}}>Date</th>
                <th>Target Group</th>
              {/* <th>Status</th> */}
              </tr>
          </thead>
          {keyword.length && records.length && filtredRecords.length === 0?<tbody><tr><td colSpan={4}><Text mt={'xl'} sx={{width:'100%',textAlign:'center'}}>No records match that search</Text></td></tr></tbody>
          :<tbody>{rows}</tbody>
          }
          
          </Table>
          </ScrollArea>
          
        </Container>
    </Modal>
    
  );
}

export default RecordsModal;