const STORAGE_KEY = {
    "account": "account",
    "token": "token",
    "expirationDate": "expirationDate"
};

function decode(k) {
    if (!k) {
        return "";
    }
    return atob(k.replace(/-/g, ""));
}

function encode(s) {
    return btoa("" + s).replace(/(.{5})/g, "$1-");
}

function getAPIKey() {
    return decode(localStorage.getItem("facemedemosystem_apikey"));
}

function getToken() {
    return decode(localStorage.getItem("facemedemosystem_token"));
}

function openFileDialog(accept, multy = false, callback)
{
    // Create an input element
    var inputElement = document.getElementById("readFile");

    // Set its type to file
    inputElement.type = "file";

    // Set accept to the file types you want the user to select. 
    // Include both the file extension and the mime type
    inputElement.accept = accept;

    // set onchange event to call callback when user has selected file
    inputElement.onchange = callback

    inputElement.onclick = (e) =>{
        e.target.value = null
    }

    // dispatch a click event to open the file dialog
    inputElement.dispatchEvent(new MouseEvent("click")); 
}									

function blobtoDataURL(blob, callback) {
    var fr = new FileReader();
    fr.onload = function(e) {
        callback(e.target.result);
    };
    fr.readAsDataURL(blob);
}

export const dataURItoBlob = (dataURI) => {
    console.log(dataURI, ' URI')
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

function cacheAPIKey(apikey) {
    localStorage.setItem("facemedemosystem_apikey", encode(apikey));
}    

function clearAPIKey() {
    localStorage.removeItem("facemedemosystem_apikey");
}    

function cacheToken(token) {
    localStorage.setItem("facemedemosystem_token", encode(token));
}    

function clearToken() {
    localStorage.removeItem("facemedemosystem_token");
}

function checkPersonData(name, email){
    var errors = [];
    if (name != null && !name) {
        errors.push("Name cannot be empty.");
    }
    if (!email) {
        errors.push("Email cannot be empty.");
    }
    if (name != null && name.length > 45) {
        errors.push("The length of name should under 45 characters long.");
    }
    if (email.length > 50) {
        errors.push("The length of email should under 50 characters long.");
    }
    if (!/^[\x00-\x7F]*$/.test(email)) {
        errors.push("Only ASCII character allowed in name");
    }
    const emailTester = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailTester.test(email)) {
        errors.push("Email format incorrect.");
    }
    if (errors.length === 0) {
        return null;
    }
    return errors;
}