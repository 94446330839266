import { Avatar, Box, Button, Group } from "@mantine/core";
import { useRef, useEffect, useState } from "react";
import { antiSpoofing, checkAntispoofingSetting } from "./Services";
var $ = require( "jquery" );

export const FaceDetect = ({onConfirm,onPrevious,user}) => {
    const facemeRef = useRef(null);
    const [imgsrc, setImgsrc] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {

    (function (d, s) {
        var e = d.createElement(s);
        e.type = 'text/javascript';
        e.async = true;
        e.src = './sdk/faceme-web-sdk.js';
        var x = d.getElementsByTagName(s)[0];
        x.parentNode.insertBefore(e, x);
    })(document, 'script');
    $(function(){
        $("#camera_region").hide()
        $(this).ready(function(){
            document.addEventListener('reload_fmd', function (e) {
                try{
                    //$("#faceme-module").hide()
                    console.log('[html] handleLoad window.FaceMe isLoaded:', window.FaceMe.isLoaded());
                    if (window.FaceMe.isLoaded())
                        window.FaceMe.stopAntispoofing()
                    handleLoad()
                    $("#faceme-module").show()
                }
                catch(e){
                    
                }
            }, false);
            
            document.addEventListener('close_fmd', function (e) {
                try{
                    window.FaceMe.stopAntispoofing()
                    console.log('[html] close_fmd')
                }
                catch(e){

                }
                
            }, false);

            document.addEventListener('resize_fmd', function (e) {
                try{
                    //window.FaceMe.close();
                }
                catch(e){

                }
                
            }, false);            
            
            var camera_selector = $("#selectedCamera")
            camera_selector.change(function () {
                    var val = $(this).val();
                    window.FaceMe.changeCamera(val)
                });
        })
    })

    var imageMode = false;
    var isDebug = true;
    var enableStrictMode = false;
    //check some settings
    checkAntispoofingSetting();
    //data
    var antiSpoofingType = localStorage.getItem("antiSpoofingType");
    let precisionLevel = localStorage.getItem("precisionLevel");
    let raNum = antiSpoofingType === "TWO_STAGES_WITH_RANDOM_ACTIONS"?localStorage.getItem("raNum"):'1';
    let raNodEnable = localStorage.getItem("raNodEnable");
    let raSmileEnable = localStorage.getItem("raSmileEnable");    
    var latestFace = null
    var strictFace = null
    var frontFace = null

    const handleLoad = () => {
        console.log('[html] handleLoad window.FaceMe isLoaded:', window.FaceMe.isLoaded());
        window.FaceMe.startAntiSpoofing(
            {}, 
            getDetectSetting('enable'),
            handleFaceResult, 
            failCallback
        );
        
    };

    const showImage = (data, i) => {
        let urlCreator = window.URL || window.webkitURL;
        let img = document.createElement("img");
        img.src = urlCreator.createObjectURL(data);
        img.title = i;
        this.document.body.appendChild(img);
    };

    const passImage = (data, i) => {
        let urlCreator = window.URL || window.webkitURL;
        var img = document.querySelector("#capture_face")
        if (img)
            img.src = urlCreator.createObjectURL(data);
        localStorage.setItem("cameraoriginsrc", urlCreator.createObjectURL(data))
        //img.title = i;
    };

    const passFailResult = (data, i) => {
        localStorage.setItem("cameraoriginsrc_error", JSON.stringify(data))
    }

    const download = (data, name) => {
        let link = document.createElement('a');
        link.download = name;

        let reader = new FileReader();
        reader.readAsDataURL(data); // converts the blob to base64 and calls onload

        reader.onload = function () {
            link.href = reader.result; // data url
            link.click();
        };
    };

    const detectImage = async () => {
        console.log('detectImage')
    }

    window.facemeAsyncInit = function () {
        console.log('[html] SDK loaded');
        
        setTimeout(() => {
               
        window.FaceMe.init( () => {
            if(imageMode){
                detectImage();
            }
            else{
                window.FaceMe.startAntiSpoofing(
                    {
                        debug: false,
                        sdkBlockId: 'faceme-module', // must
                        frontSetting: {},
                        rightSetting: {},
                        preferCameraList: ['C920'],
                        enableStrictMode: false,       // true: remove blob image type: "image/jpeg"
                        // actionList: ['LEFT', 'RIGHT', 'NOD', 'SMILE'],
                    }, 
                    getDetectSetting('enable'),
                    handleFaceResult, 
                    failCallback
                );

                window.FaceMe.addEventListener('error', function (response) {
                    console.log('[html] error:', response);
                    passFailResult({errorState:"ERROR", errorType:response.code, errorMessage:response.message, isReload:true}, 1)
                    localStorage.setItem("cameraoriginsrc_errordebug", response.code + " : " + response.message)
                    localStorage.setItem("facedetect_state", "antispoofing_done")                        
                    var detectDone = $("#face_detect_error")
                    //detectDone.click()
                    onDetectClick();
                    window.FaceMe.stopAntispoofing()
                    window.FaceMe.release()
                });
                window.FaceMe.addEventListener('getMultiCamera', function (devices) {
                    console.log('[html] getMultiCamera:', devices);
                    var camera_selector = $("#selectedCamera")
                    var selected_label = ""
                    camera_selector.empty();
                    devices.map(d => {
                        const label = d.label
                        camera_selector.append($('<option>', { 
								value: label,
								text : label,
							}));
                        if (d.selected)
                            selected_label = d.label
                        if (selected_label === "")
                            selected_label = d.label
                    });
                    if (selected_label)
                    {
                        camera_selector.val(selected_label)
                        if (devices.length>1)
                            $("#camera_region").show()
                        else
                            $("#camera_region").hide()
                    }
                    else {
                        $("#camera_region").hide()
                    }
                });
                window.FaceMe.addEventListener('nextFace', function (nextFaceType) {
                    console.log('event next face:', nextFaceType);
                });
            }
        }, failCallback);


        },1000)
     
    };

    const successCallback = (faceType, detail, smallImages, largeImages, strictImages, cropImages) => {
        console.log('[html] ------------------------');
        console.log('[html] getResult face type:', faceType);
        console.log('[html] getResult detail:', detail);
        console.log('[html] getResult smallImages:', smallImages);
        console.log('[html] successCallback getResult cropImages:', cropImages);
    };

    const successCallback2 = (faceType, detail, smallImages, largeImages, strictImages, cropImages) => {
        console.log('[html] ------------------------');
        console.log('[html] getResult detail:', detail);
        console.log('[html] getResult smallImages:', smallImages);
        console.log('[html] getResult largeImages:', largeImages);
        console.log('[html] getResult strictImages:', strictImages);
        console.log('[html] successCallback2 getResult cropImages:', cropImages);

        smallImages.image1 && smallImages.image1.forEach((data, i) => {
            showImage(data, i);
        });
        smallImages.image2 && smallImages.image2.forEach((data) => {
            data.forEach((image, i) => showImage(image, i));
        });
        largeImages.forEach((data, i) => {
            showImage(data, i);
        });
        // strictImages.forEach((data, i) => {
        //     showImage(data, i);
        // });
        cropImages.forEach((data, i) => {
            showImage(data, i);
        });
    };

    async function handleFaceResult(detail, spoofingcheckImages, clearImage) {
        console.log('[html] ------------------------');
        console.log('[html] getResult detail:', detail);
        console.log('[html] getResult spoofingcheckImages:', spoofingcheckImages);
        console.log('[html] getResult strictImages:', clearImage);
        var detectDone = $("#face_detect_done")

        if (antiSpoofingType === "TWO_STAGES_WITH_RANDOM_ACTIONS") {
            latestFace = clearImage[clearImage.length - 1];
            strictFace = clearImage[clearImage.length - 1];
            frontFace = clearImage[clearImage.length - 1];
        } else {
            //we use the last face image as primary face image and used it for later.
            latestFace = clearImage.length > 0 ? clearImage[clearImage.length - 1] : latestFace;
            //we also save the last front face image for result page.
            frontFace = clearImage[clearImage.length - 1];
            //we save the last strict image for enroll and sign in purpose.
            strictFace = clearImage[clearImage.length - 1];
        }

        if (antiSpoofingType === "OFF") {
            localStorage.setItem("facedetect_state", "antispoofing_done")
            passImage(frontFace, 0);
            //detectDone.click();
            onDetectClick();

        }
        else
        {
            passImage(frontFace, 0);
            localStorage.setItem("facedetect_state", "antispoofing_progressing")
            //detectDone.click()
            onDetectClick();

            try{
                let responseArray = [];
                responseArray = await antiSpoofing(detail, spoofingcheckImages, {
                        enableStrictMode: enableStrictMode
                    });

                if (responseArray.length === 0) {
                    //Show timout message and close window.FaceMe SDK if request timeout
                    //this.$emit("submit-face", "ERROR", this.latestFace, this.strictFace, "Time out");
                    passFailResult({errorState:"ERROR", errorType:"Time out", errorMessage:"Time out"}, 0)
                    localStorage.setItem("cameraoriginsrc_errordebug", "Error : Time out")
                    localStorage.setItem("facedetect_state", "antispoofing_done")
                    return;
                }
                const response = responseArray[0].json;
                if (response.hasOwnProperty("statusCode")) {
                    //Show error message and close window.FaceMe SDK if request failed
                    //this.$emit("submit-face", "ERROR", this.latestFace, this.strictFace, response.errorMessage);
                    passFailResult({errorState:"ERROR", errorType:response.statusCode, errorMessage:response.errorMessage}, 1)
                    localStorage.setItem("cameraoriginsrc_errordebug", response.statusCode + " : " + response.errorMessage)
                    localStorage.setItem("facedetect_state", "antispoofing_done")
                    return;
                }   

                if (response.result.toLowerCase() === "spoofing") {
                    //Send fail result to pages if server tell us the face we requested is not a real person.
                    //this.$emit("submit-face", "FAIL", this.latestFace, this.strictFace, "");
                    passFailResult({errorState:"FAIL", errorType:"Spoofing", errorMessage:"The face is not a real person."}, 2)
                    localStorage.setItem("cameraoriginsrc_errordebug", "Spoofing Fail : The face we requested is not a real person.")
                } else if (response.result.toLowerCase()  === "liveness") {
                    //Send success front face to pages if server tell us the face we requested is a real person.
                    // if (responseArray.length > 1) {
                    //     //We use the face image which server recommended if there's any.
                    //     this.frontFace = responseArray[1].file;
                    // }
                    //this.$emit("submit-face", "SUCCESS", this.frontFace, this.strictFace, "");
                    localStorage.removeItem("cameraoriginsrc_error")
                    localStorage.removeItem("cameraoriginsrc_errordebug")
                } else {
                    //console.error(`unknow type: ${response.result}`);
                    passFailResult({errorState:"FAIL", errorType:response.result, errorMessage:`unknow type: ${response.result}`}, 3)
                    localStorage.setItem("cameraoriginsrc_errordebug", "Fail for unknow type: " + response.result)
                }

                //complete
                localStorage.setItem("facedetect_state", "antispoofing_done")
            }
            catch(e)
            {
                passFailResult({errorState:"FAIL", errorType:"Exception", errorMessage:"Exception for face detection"}, 5)
                localStorage.setItem("facedetect_state", "antispoofing_done")
            }
            finally{
            }

        }

    };

    const failCallback = (response) => {
        console.log('[html] getFail:', response);
    };

    const getDetectSetting = (settingType) => {
        return settingType === 'enable' ? {
            always2Stages: false,
            //antiSpoofingType: antiSpoofingType,
            antiSpoofingType: "OFF",
            precisionLevel: precisionLevel,
            raNum: parseInt(raNum),
            raNodEnable: (raNodEnable.toLowerCase() === 'true'),
            raSmileEnable: (raSmileEnable.toLowerCase() === 'true'),
        } : {
            antiSpoofingType: "OFF",
        }
    }


    },[])

    const GROUP_ALL = {groupUuid:"ALL", isALL:true, name:"Not Specified"}
    const serverData = {faceInfo:null,qualityIssue:null,group:null, eraseUsers:null}
    const fillData = {group:GROUP_ALL}
    var frameDraw = new Image();
    var aspectRatio = 1;

    const onDetectClick = function(){
        serverData.errorResult = null
        frameDraw.onload = () =>{
            var w = frameDraw.width
            var h = frameDraw.height
            if (w > 0 && h >0){
                if (aspectRatio >= 1)
                {
                    w = window.innerHeight / frameDraw.height * frameDraw.width
                    h = window.innerHeight
                    $("#snapshot_frame").css("width", w + "px")
                    $("#snapshot_frame").css("height", h + "px")
                }
                else{
                    w = window.innerWidth
                    h = window.innerWidth / frameDraw.width * frameDraw.height
                    $("#snapshot_frame").css("width", w + "px")
                    $("#snapshot_frame").css("height", h + "px")
                }
                $("#progress-container").css("bottom", Math.max(40, (window.innerHeight - h)/2) + "px")
            }
            var c = document.createElement("canvas");
            c.width = frameDraw.width
            c.height = frameDraw.height
            var ctx = c.getContext("2d");
            //var img = document.querySelector("#capture_face")
            ctx.drawImage(frameDraw,0,0,frameDraw.width,frameDraw.height);
            c.toBlob(function (blob) {
                fillData.imgData = blob;
                onConfirm({data:blob,url:data});
                //loadpage("#authening_face", "#enroll_by_camera_main");
            }, "image/jpeg", 1);

        }
        var data = localStorage.getItem("cameraoriginsrc")
        frameDraw.src = data;

        setImgsrc(data);

    }

    const onAcceptClick = () =>{
        onConfirm({data:imgsrc});

    }

    return (

        <>
        
            <div ref={facemeRef} style={{width:'100%', height:'100%'}}>

            {currentStep === 0?
                <Group sx={{flexDirection:'column', width:'100%', height:'100%'}}>
                    <div id="faceme-module" style={{width: '100%', height: '100%'}}></div>
                    <div id="camera_region" style={{height:'auto',margin: '10px 0 10px 0',display:'flex',alignItems: 'center', justifyContent: 'center'}}>
                        {/* <span style={{marginRight:4}}>Camera: </span>                         */}
                    </div>
                    {/* <button id="face_detect_done" onClick={() => onDetectClick()} ></button> */}
                </Group>
            :
                <Box sx={{flexDirection:'column', width:'100%', height:'100%', textAlign:'center'}}>
                    {imgsrc?
                        <Avatar src={imgsrc} style={{width:'100%', height:'auto', borderRadius:10}} />
                    :null
                    }

                    <Group position="center" mt={'lg'}>
                        <Button variant="default" onClick={() => onAcceptClick()}>Previous</Button>
                        <Button variant="light" onClick={() => {setCurrentStep(0); window.facemeAsyncInit()}}>Try Again</Button>
                        <Button onClick={() => onAcceptClick()}>Accept</Button>
                    </Group>
                        
                </Box>
            }

                            {/* <div style={{display:'flex',flexDirection: 'column', width:800,height:800,alignContent: 'center',justifyContent: 'center', alignItems: 'center'}}> */}
                            
                            
                            
				{/* <div id="enroll_by_camera_main" class="column aligncenter fullpanel">
					<div id="camera_area" class="row aligncenter fullpanel">
					</div>
					<button id="face_detect_done" onClick={() => onDetectClick()} ></button>
					<button id="face_detect_error"></button>
				</div>		 */}

               {/* authenticating */}
				{/* <div id="authening_face" class="row aligncenter" style={{display:'block', background:'black'}}> */}
					{/* <div id="faceme-container"  className="row aligncenter fullpanel">
						<img id="snapshot_frame" src="./assets/images/FaceMatch_default_avatar.png" 
								style={{height: '100%', 
										width: '100%', 
										opacity: .5}}/>
					</div> */}

                    

                   

					{/* <div id="progress-container" style={{display:'block',position:'absolute',height:'70px',width:'100%',bottom:40}}>
						<div style={{display:'flex',width:'100%',justifyContent: 'center'}}>
							<div class="column" style={{height:'100%',width:'270px'}}>
								<span style={{color:'white',fontSize:'24px',padding:'10px',width:'100%',textAlign:'center',padding:'0 0 20px 0'}}>
									Authenticating
								</span>
								<div id="faceme-capture-progress" style={{borderRadius: '15px',backgroundColor:'rgb(200,200,200)',width: '100%'}}>
									<div id="faceme-capture-progress-data" style={{height:'14px',width:0,borderRadius:'15px 0 0 15px',backgroundColor:'rgba(0,128,255,1)'}}></div>
								</div>
								<span id="progress" style={{color:'white',fontSize:'18px',padding:'10px',width:'100%',textAlign:'center',padding:'20px 0 0 0'}}>
									Please wait
								</span>
							</div>
						</div>
					</div>						 */}
				{/* </div> */}
    </div>

    </>

    )
}