import { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import poster from '../assets/images/poster.png';

const videoJsOptions = {
    autoplay: true,
    controls: false,
    muted: true,
    // responsive: false,
    fluid: true,
    poster:poster,
    fill:false,
    
  };


const VideoFeed = ({ src }) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState();

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!player) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      setPlayer(
        videojs(videoElement, videoJsOptions, () => {
          console.log("player is ready");
        })
      );
    }
  }, [videoRef,player]);

  useEffect(() => {
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [player]);

  return (
    
      <video className="video-js" ref={videoRef}>
        <source src={src} type="application/x-mpegURL" />
        {/* <source src={'//vjs.zencdn.net/v/oceans.mp4'} type="application/x-mpegURL" /> */}
      </video>
    
  );
};


export default VideoFeed;